import { useEffect, useState } from 'react';
import 'antd/dist/antd.min.css';
import {
  AppleOutlined,
  AppstoreOutlined,
  CarOutlined,
  DashboardOutlined,
  DesktopOutlined,
  EditOutlined,
  FileOutlined,
  LoginOutlined,
  LogoutOutlined,
  SearchOutlined,
  SelectOutlined,
  ShoppingOutlined,
  UnorderedListOutlined,
  UnlockOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  StarOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.png';
import styles from './Sider.module.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import { ROLES } from '../../types/constants';

interface iItem {
  label: any;
  key: string;
  icon?: any;
  children?: iItem[];
  role: Array<string>;
}

function getItem(
  label: any,
  key: string,
  icon?: any,
  children?: iItem[],
  role: Array<string> = [],
): iItem {
  return {
    key,
    icon,
    children,
    label,
    role,
  };
}

/**
 * Для авторизованных пользователей
 */
const authItemContent = [
  getItem(
    'Товары',
    '2',
    <DesktopOutlined />,
    [
      getItem(<Link to="/products/add">Добавить товар</Link>, '21'),
      getItem(<Link to="/products/list">Список товаров</Link>, '22'),
      getItem('Типы товаров', '23', null, [
        getItem(<Link to="/types/add">Добавить тип</Link>, '15211'),
        getItem(<Link to="/types/list">Список типов</Link>, '24'),
      ], [ROLES.admin, ROLES.content]),
    ],
    [ROLES.content],
  ),
  getItem(
    'Категории',
    '3',
    <AppstoreOutlined style={{ width: 16, height: 16 }} />,
    [
      getItem(<Link to="/categories/add">Добавить категорию</Link>, '31'),
      getItem(<Link to="/categories/list">Список категорий</Link>, '32'),
    ],
    [ROLES.content],
  ),
  getItem(
    'Доп. услуги',
    '14',
    <ShoppingCartOutlined style={{ width: 16, height: 16 }} />,
    [
      getItem(<Link to="/services/add">Добавить услугу</Link>, '141'),
      getItem(<Link to="/services/list">Список услуг</Link>, '142'),
    ],
    [ROLES.content],
  ),
  getItem(
    'Атрибуты',
    '8',
    <EditOutlined />,
    [
      getItem('Атрибуты', '81', null, [
        getItem(
          <Link to="/attributes/attributes/add">Добавить атрибут</Link>,
          '811',
        ),
        getItem(
          <Link to="/attributes/attributes/list">Список атрибутов</Link>,
          '812',
        ),
      ]),
      getItem('Группы атрибутов', '82', null, [
        getItem(
          <Link to="/attributes/groups/add">Добавить группу</Link>,
          '821',
        ),
        getItem(<Link to="/attributes/groups/list">Список групп</Link>, '822'),
      ]),
    ],
    [ROLES.content],
  ),
  getItem(
    'Контент',
    '10',
    <FileOutlined />,
    [
      getItem('Галереи', '101', null, [
        getItem(
          <Link to="/content/sliders/add">Добавить галерею</Link>,
          '1011',
        ),
        getItem(<Link to="/content/sliders/list">Список галерей</Link>, '1012'),
      ]),
      getItem('Ленты продуктов', '102', null, [
        getItem(
          <Link to="/content/product-slider-group/add">Добавить ленту</Link>,
          '1021',
        ),
        getItem(
          <Link to="/content/product-slider-group/list">Список лент</Link>,
          '1022',
        ),
      ]),
      getItem('Гео', '103', null, [
        getItem('Геозоны', '1031', null, [
          getItem(
            <Link to="/content/geo/geo-zone/add">Добавить геозону</Link>,
            '10311',
          ),
          getItem(
            <Link to="/content/geo/geo-zone/list">Список геозон</Link>,
            '10312',
          ),
        ]),
        getItem('Регионы', '1032', null, [
          getItem(
            <Link to="/content/geo/geo-region/add">Добавить регион</Link>,
            '10321',
          ),
          getItem(
            <Link to="/content/geo/geo-region/list">Список регионов</Link>,
            '10322',
          ),
        ]),
        getItem('Города', '1033', null, [
          getItem(
            <Link to="/content/geo/geo-city/add">Добавить город</Link>,
            '10331',
          ),
          getItem(
            <Link to="/content/geo/geo-city/list">Список городов</Link>,
            '10332',
          ),
        ]),
      ]),
      getItem('Новости', '104', null, [
        getItem(<Link to="/content/news/add">Добавить новость</Link>, '1041'),
        getItem(<Link to="/content/news/list">Список новостей</Link>, '1042'),
      ]),
      getItem('Страницы', '105', null, [
        getItem(<Link to="/content/pages/add">Добавить страницу</Link>, '1051'),
        getItem(<Link to="/content/pages/list">Список страниц</Link>, '1052'),
      ]),
      getItem('Изображения', '106', null, [
        getItem(
          <Link to="/content/images/add">Добавить изображение</Link>,
          '1061',
        ),
        getItem(
          <Link to="/content/images/list">Список изображений</Link>,
          '1062',
        ),
      ]),
      getItem('Статьи', '107', null, [
        getItem(<Link to="/content/articles/add">Добавить статью</Link>, '1071'),
        getItem(<Link to="/content/articles/list">Список статей</Link>, '1072'),
      ]),
    ],
    [ROLES.content],
  ),
];

const authItemSEO = [
  getItem(
    ROLES.seo,
    '13',
    <SearchOutlined />,
    [
      getItem(<Link to="/content/news/list">Новости</Link>, '131'),
      getItem(<Link to="/products/list">Товары</Link>, '132'),
      getItem(<Link to="/content/pages/main">Главная страница</Link>, '133'),
      getItem(<Link to="/content/pages/list">Страницы</Link>, '134'),
      getItem(<Link to="/categories/list">Категории</Link>, '135'),
      getItem(<Link to="/seo-files">Файлы</Link>, '136'),
    ],
    [ROLES.seo],
  ),
];

const authItemAdmin = [
  getItem(
    'Админ панель',
    '19',
    <DashboardOutlined />,
    [
      getItem(<Link to="/dashboard">Главная страница</Link>, '1'),
      getItem(<Link to="/constants">Константы</Link>, '18', null, undefined, [
        ROLES.admin,
      ]),
    ],
    [ROLES.content],
  ),
  getItem(
    'Управление доступом',
    '15',
    <UnlockOutlined />,
    [
      getItem(<Link to="/users/list">Пользователи</Link>, '151'),
      getItem('Роли', '152', null, [
        getItem('Управление ролями', '1521', null, [
          getItem(<Link to="/roles/add">Добавить роль</Link>, '20'),
          getItem(<Link to="/roles/list">Список ролей</Link>, '15212'),
        ]),
        getItem(<Link to="/roles/access-map">Карта доступа</Link>, '1522'),
      ]),
    ],
    [ROLES.admin],
  ),
  getItem(
    'Производители',
    '4',
    <AppleOutlined />,
    [
      getItem(<Link to="/producers/add">Добавить производителя</Link>, '41'),
      getItem(<Link to="/producers/list">Список производителей</Link>, '42'),
    ],
    [ROLES.content],
  ),
  getItem(
    'Магазины',
    '5',
    <ShoppingOutlined />,
    [
      getItem(<Link to="/shops/add">Добавить магазин</Link>, '51'),
      getItem(<Link to="/shops/list">Список магазинов</Link>, '52'),
    ],
    [ROLES.content],
  ),
  getItem('Заказы', '6', <UnorderedListOutlined />, [
    getItem(<Link to="/orders/list">Список заказов</Link>, '61'),
    getItem(<Link to="/order-history/list">История заказов</Link>, '62'),
  ]),
  getItem(<Link to="/users/list">Пользователи</Link>, '7', <UserOutlined />),
  getItem(<Link to="/comments/list">Отзывы</Link>, '17', <StarOutlined />),
  getItem(
    'Сервисы',
    '9',
    <CarOutlined />,
    [
      getItem(
        'Оплата',
        '91',
        null,
        [
          getItem(
            'Способы оплаты',
            '911',
            null,
            [
              getItem(
                <Link to="/services/payment/payment/add">Добавить способ</Link>,
                '9111',
              ),
              getItem(
                <Link to="/services/payment/payment/list">Способы оплаты</Link>,
                '9112',
              ),
            ],
            [ROLES.admin],
          ),
          getItem(
            'Онлайн платежи',
            '912',
            null,
            [
              getItem(
                <Link to="/services/payment/payment-online/add">
                  Добавить платёж
                </Link>,
                '9121',
              ),
              getItem(
                <Link to="/services/payment/payment-online/list">
                  Список платежей
                </Link>,
                '9122',
              ),
            ],
            [ROLES.admin],
          ),
          getItem('Платежи по ссылке', '913', null, [
            getItem(
              <Link to="/services/payment/payment-reference/add">
                Добавить платёж
              </Link>,
              '9131',
            ),
            getItem(
              <Link to="/services/payment/payment-reference/list">
                Список платежей
              </Link>,
              '9132',
            ),
          ]),
          getItem(<Link to="/kkm">Чеки ОФД</Link>, '914')
        ],
        [ROLES.operator],
      ),
      getItem(
        'Доставка',
        '92',
        null,
        [
          getItem(
            <Link to="/services/deliveries/add">Добавить способ</Link>,
            '921',
          ),
          getItem(
            <Link to="/services/deliveries/list">Способы доставки</Link>,
            '922',
          ),
        ],
        [ROLES.admin],
      ),
    ],
    [ROLES.operator],
  ),
  getItem('Меню', '11', <SelectOutlined />, [
    getItem('Меню', '111', null, [
      getItem(<Link to="/menu/menu/add">Добавить меню</Link>, '1111'),
      getItem(<Link to="/menu/menu/list">Список меню</Link>, '1112'),
    ]),
    getItem('Пункты меню', '112', null, [
      getItem(
        <Link to="/menu/menu-item/add">Добавить пункт меню</Link>,
        '1121',
      ),
      getItem(<Link to="/menu/menu-item/list">Пункты меню</Link>, '1122'),
    ]),
  ]),
  getItem(
    'Доп. возможности',
    '16',
    <SettingOutlined style={{ width: 16, height: 16 }} />,
    [
      getItem(<Link to="/manual-requests">Ручные запросы</Link>, '15213')
    ],
    [ROLES.content],
  ),
  ...authItemContent,
  ...authItemSEO,
];

/**
 * Для неавторизованных пользователей
 */
const items = [
  getItem(<Link to="/login">Авторизация</Link>, '12', <LoginOutlined />),
];

export const Sidebar = () => {
  const { logout } = useActions();
  const { isAuth, isAdmin } = useTypedSelector((state) => state.auth);
  const [collapsed, setCollapsed] = useState(false);
  const [authItem, setAuthItem] = useState(authItemContent);
  const { user } = useTypedSelector((state) => state.auth);

  const filterItems = (items: iItem[], parentRoles: string[] = []) => {
    const userRoles = user?.roles?.map((role) => {
      return role.value;
    });
    const result = [];
    for (const item of items) {
      if (!item.role || item.role.length === 0) item.role = parentRoles;

      for (const itemRole of item.role) {
        if (userRoles?.includes(itemRole)) {
          result.push(item);
          if (item && item?.children)
            item.children = filterItems(item.children, item.role);
        }
      }
    }
    return result;
  };

  let authItemContentFilter = authItemAdmin;
  if (!isAdmin) {
    authItemContentFilter = filterItems(authItemContentFilter);
  }

  useEffect(() => {
    setAuthItem([...authItemContentFilter]);
  }, [isAuth]);
  return (
    <Layout
      style={{
        minHeight: '100vh',
      }}
    >
      <Layout.Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={250}
        style={{ overflow: 'hidden' }}
      >
        <div className={styles.logo}>
          <Link to="/dashboard">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          style={{ fontSize: 16 }}
          items={
            isAuth
              ? [
                  ...authItem,
                  getItem(
                    <Link to="/login" onClick={() => logout()}>
                      Выйти из аккаунта
                    </Link>,
                    '12',
                    <LogoutOutlined />,
                  ),
                ]
              : items
          }
        />
      </Layout.Sider>
    </Layout>
  );
};
